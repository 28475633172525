import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {PrimarySearchAppBarProps} from "../Schedule";
import ScreenRotationAltIcon from '@mui/icons-material/ScreenRotationAlt';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import {Link, Tooltip} from "@mui/material";

export default function PrimarySearchAppBar(props: PrimarySearchAppBarProps) {
    const handlePoolChange = (event: SelectChangeEvent) => {
        props.onPoolChange(event.target.value as string);
    };

    const toggleBox = () => {
        props.onShowWeekChange();
    };

    // const togglePreviousWeek = () => {
    //     props.onNextWeekChange(-1);
    // }

    const toggleNextWeek = () => {
        props.onNextWeekChange(1, true);
    }

    const renderPoolSelect = (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.poolId}
            onChange={handlePoolChange}
            sx={{
                color: "#ffffff",
                // color: "#3a3a3a",
                backgroundColor: "#3eabee",
                // backgroundColor: "#cee2f1",
                height: "40px",
                maxWidth: "70%"
            }}
        >
            {/*<MenuItem value={"decin-hala"}>Děčín, Hala, 25m</MenuItem>*/}
            <MenuItem value={"sareza-jesterka"}>Ostrava, Ještěrka, 25m</MenuItem>
            <MenuItem value={"sareza-vodni-svet"}>Ostrava, Vodní svět, 25m</MenuItem>
            <MenuItem value={"aquapce-25"}>Pardubice, Aquacentrum, 25m</MenuItem>
            <MenuItem value={"aquapce-50"}>Pardubice, Aquacentrum, 50m</MenuItem>
            <MenuItem value={"aquacentrum-sutka"}>Praha, Aquacentrum Šutka, 50m</MenuItem>
            <MenuItem value={"centrum-olsanka"}>Praha, Olšanka, 25m</MenuItem>
            {/*<MenuItem value={"na-vystavisti"}>Praha, Na Výstavišti, 25m</MenuItem>*/}
            <MenuItem value={"podoli-indoor-50"}>Praha, Podolí, 50m, vnitřní</MenuItem>
        </Select>
    );

    return (
        <Box sx={{flexGrow: 1, paddingTop: "10px"}}>
            <AppBar position="fixed"
                    sx={{backgroundColor: "#0498f1"}}
            >
                <Toolbar>
                    {/*TODO add Swimslot logo*/}
                    {/*<IconButton*/}
                    {/*    size="large"*/}
                    {/*    edge="start"*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="open drawer"*/}
                    {/*    sx={{ mr: 2 }}*/}
                    {/*>*/}
                    {/*    <CalendarMonthOutlinedIcon />*/}
                    {/*</IconButton>*/}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{display: {xs: 'none', sm: 'block'}, paddingRight: "15px"}}
                    >
                        <Link color="inherit" href="/" underline="none">
                            Swimslot
                        </Link>
                    </Typography>
                    {renderPoolSelect}

                    <Box sx={{flexGrow: 1}}/>
                    {/*<Tooltip title={"Předchozí týden"} enterDelay={1000} >*/}
                    {/*    <IconButton size="large" onClick={togglePreviousWeek} color="inherit" sx={{display: {xs: 'none', sm: 'block'}}}>*/}
                    {/*        <ArrowCircleLeftOutlinedIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    {props.showWeekArrows &&
                        <Tooltip title={"Další týden"} enterDelay={1000}>
                            <IconButton size="large" onClick={toggleNextWeek} color="inherit">
                                <ArrowCircleRightOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title={"Den nebo Týden"} enterDelay={1000}>
                        <IconButton size="large" onClick={toggleBox} color="inherit">
                            <ScreenRotationAltIcon/>
                        </IconButton>
                    </Tooltip>

                </Toolbar>
            </AppBar>
        </Box>
    );
}